<script>
import { useFunnelStep } from "@/composables/funnelStep";
import { downloadPDF, getFilenameFromResponse } from "@/helpers/downloadFile";
import getFormattedPrice from "@/helpers/getFormattedPrice";
import getFormattedPercentage from "@/helpers/getFormattedPercentage";
import MerchantService from "@/api/merchantService";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import DialogModal from "@/components/DialogModal.vue";
import Loader from "@/components/BaseLoader.vue";
import { mapGetters } from "vuex";
import { MERCHANT } from "@/store/namespaces";

export default {
  name: "FunnelIntroduction",
  components: {
    Loader,
    DialogModal,
    SofincoLogo,
    ScreenTitle,
  },
  setup(_, ctx) {
    const { funnelStepDone } = useFunnelStep(ctx);
    return { funnelStepDone };
  },
  data() {
    return {
      fipenError: false,
      fipenLoading: false,
      errorMessage: undefined,
      simulationData: undefined,
    };
  },
  computed: {
    ...mapGetters(["rawCurrency", "rawSignature"]),
    ...mapGetters(MERCHANT, [
      "merchant",
      "isPledgLongCb",
      "dataPolicyUrl",
      "installmentsNb",
      "isBuyerContractEligible",
    ]),
    introduction() {
      return [
        {
          title: this.$t("your_purchase"),
          data: [
            {
              label: this.$t("purchase_amount"),
              data_cy: "purchase-amount",
              value: this.simulationData.amount,
            },
            {
              label: this.$t("intro_deposit_amount"),
              data_cy: "deposit-amount",
              value: this.simulationData.deposit_amount,
            },
            {
              label: this.$t("intro_credit_amount"),
              data_cy: "financed-amount",
              value: this.simulationData.financed_amount,
            },
          ],
        },
        {
          title: this.$t("your_financing"),
          modal: {
            triggerLabel: this.$t("read_more"),
            modalContent: this.$t("intro_financing_modal_text", this.simulationData),
          },
          data: [
            {
              label: this.$t("intro_financing_period"),
              data_cy: "financing-period",
              value: this.$t("intro_financing_period_value", {
                installments_nb: this.simulationData.financing_duration_in_months,
              }),
            },
            {
              label: this.$t("monthly_installments", {
                installments_nb: this.simulationData.financing_duration_in_months,
              }),
              data_cy: "derived-share-amount",
              value: `<span class="text-2xl">${this.simulationData.derived_share_amount}</span>`,
            },
            {
              label: this.$t("intro_taeg"),
              data_cy: "taeg",
              value: `<span class="text-2xl">${this.simulationData.taeg}</span>`,
            },
            {
              label: this.$t("fixed_borrowing_rate"),
              data_cy: "fixed-borrowing-rate",
              value: this.simulationData.buyer_fees_percentage,
            },
            {
              label: this.$t("intro_credit_cost"),
              data_cy: "fees-amount",
              value: this.simulationData.fees_amount,
            },
            {
              label: this.$t("intro_total_amount_due"),
              data_cy: "total-amount-due",
              value: `<span class="text-2xl">${this.simulationData.financed_total_outstanding_amount}</span>`,
            },
          ],
        },
        {
          title: this.$t("your_payment"),
          data: [
            {
              label: this.$t("intro_total_payment_cost"),
              data_cy: "total-payment-amount",
              value: this.simulationData.total_amount,
            },
          ],
        },
      ];
    },
  },
  async mounted() {
    if (!this.isPledgLongCb) {
      this.funnelStepDone();
      return;
    }
    try {
      await this.loadSimulationData();
    } catch (error) {
      this.errorMessage = error?.errorHuman || this.$t("genericError");
    }
  },
  methods: {
    formatPrice(amount) {
      return getFormattedPrice(amount, this.rawCurrency, this.$i18n.locale);
    },
    formatPercentage(value) {
      return getFormattedPercentage(value, this.$i18n.locale);
    },
    async loadSimulationData() {
      const { data } = await MerchantService.simulatePayment(this.rawSignature);
      this.simulationData = {
        amount: this.formatPrice(data.amount_cents),
        deposit_amount: this.formatPrice(data.deposit_amount_cents),
        financed_amount: this.formatPrice(data.financed_amount_cents),
        financing_duration_in_months: this.installmentsNb - 1,
        derived_share_amount: this.formatPrice(data.derived_share_amount_cents),
        taeg: this.formatPercentage(data.taeg),
        buyer_fees_percentage: this.formatPercentage(data.buyer_fees_percentage),
        fees_amount: this.formatPrice(data.fees_amount_cents),
        total_amount: this.formatPrice(data.total_amount_cents),
        financed_total_outstanding_amount: this.formatPrice(
          data.financed_total_outstanding_amount_cents
        ),
      };
    },
    async downloadFipen() {
      this.fipenError = false;
      this.fipenLoading = true;
      try {
        const res = await MerchantService.fetchFipen(
          this.merchant.uid,
          { signature: this.rawSignature },
          { responseType: "blob" }
        );
        const filename = getFilenameFromResponse(res);
        downloadPDF(res.data, filename);
      } catch {
        this.fipenError = true;
      } finally {
        this.fipenLoading = false;
      }
    },
    handleSubmitClick() {
      this.funnelStepDone();
    },
  },
};
</script>

<template lang="pug">
ScreenTitle(:title="$t('intro_request_financing')")

.screen
  .screen-section
    h1 {{ $t("intro_title", {installments_nb: installmentsNb}) }}
    p.intro-subtitle.mt-5.text-center {{ $t("intro_subtitle_1") }}.
    p.intro-subtitle.text-center {{ $t("intro_subtitle_2") }}.
    p.mt-5.text-center
      template(v-if='isBuyerContractEligible && !fipenLoading') {{ $t("fipen_text") }}
        a(@click="downloadFipen" data-cy="fipen_link") {{ $t("fipen_link") }}
      Loader.m-auto(v-else-if="fipenLoading" :size="LoaderSizes.SMALL")
    .vv-error(v-if='fipenError') {{ $t("fipenDownloadError") }}

  template(v-if="simulationData")
    .screen-section
      template(v-for="section in introduction" :key="section.title")
        h4.intro-section-title {{ section.title }}
          span.float-right(v-if="section.modal")
            DialogModal(:triggerLabel="section.modal.triggerLabel" :modalContent="section.modal.modalContent")
        div.timetable
          div.timetable-row(v-for='(item, index) in section.data' :key="item.label" :class="{ 'bg-light-blue': !(index % 2), 'bg-default': index % 2 }")
            p.deadline.timetable-element
              span.intro-data-label.float-left(v-html="item.label")
              span.intro-data-value.float-right(:data-cy="item.data_cy" v-html="item.value")

    .screen-section.submit-section
      ElButton(
        class="w-1/2"
        type="primary"
        @click='handleSubmitClick'
        data-cy="submit"
      )
        .grow {{ $t("continue") }}

  .screen-section.section-error.mt-8.text-center(v-if='errorMessage') {{ errorMessage }}

  .screen-section
    .line_disclaimer
      span(v-html="$t('data_policy', { dataPolicyUrl })")

  SofincoLogo
</template>

<style scoped lang="scss">
.intro-subtitle {
  font-size: 1.625rem;
  line-height: 2rem;
}
.screen-section:first-child {
  padding-bottom: 0;
}
.screen-section.submit-section {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.line_disclaimer {
  margin-bottom: 0;
}
.intro-section-title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.25rem;
  padding: 1rem 0 1rem 0;
}
.intro-data-label {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 2rem;
}
.intro-data-value {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 2rem;
}
</style>
