export default {
  /** ----------------------------------------------------
   *                        ANCV
   *  ---------------------------------------------------- */
  title_pay_with_ancv: "Betaal met uw Chèque-Vacances Connect-account",
  try_again: "Betaling via Chèque-Vacances mislukt. Probeer het nog eens.",
  important: "belangrijk : ",
  ancv_app_mandatory:
    "Chèque-Vacances-toepassing is vereist om deze betaling te valideren",
  ancv_id: "Uw Chèque-Vacances Connect ID",
  open_ancv_app:
    "Open uw ANCV Chèque-Vacances-toepassing om het betalingsproces te voltooien.",
  transaction_success: "Uw transactie is succesvol geregistreerd",
  transaction_infos_below:
    "Hieronder vindt u de informatie met betrekking tot uw betaling",
  see_you: "Tot ziens.",
  ancv_signature: "Het team van het Nationaal Agentschap voor Chèque-Vacances.",
  ancv_amount: "Ontvangen bedrag",
  total_amount: "Gevraagd bedrag",
  ancv_transaction_id: "Transactie referentie",
  remaining_amount: "Resterend verschuldigd bedrag",
  excluding_fees: "exclusief financieringskosten",
  installment_cta:
    "Betaal het resterende verschuldigde bedrag in {installments_nb}x met een creditcard",
  deferred_cta: "Betaal later de rest met een kredietkaart",
  infos_ancv_and_bnpl:
    "Zodra het te betalen bedrag in Chèque-Vacances is gevalideerd met behulp van de Chèque-Vacances-applicatie, wordt u een betalingsschema aangeboden op basis van het resterende bedrag dat met een creditcard moet worden betaald.",
  warning_delay_bnpl:
    "U heeft {delayInMinutes} minuten om uw betaling af te ronden. Na deze tijd zal de betaling in Chèque-Vacances en uw reservering worden geannuleerd.",
  /** ----------------------------------------------------
   *                        CHECKOUT
   *  ---------------------------------------------------- */
  input_bank_details: "Ik voer mijn bankgegevens in",
  amount_to_pay: "Het te betalen bedrag",
  total_purchase_amount: "Totaalbedrag",
  split_explanation: "Als alle deelnemers binnen 48 uur betalen, betaal ik ",
  i_pay_my_share: "Ik betaal mijn deel",
  i_pay_my_installment: "Ik betaal mijn maandelijkse betaling",
  anticipated_payment_warning:
    "U bereidt zich voor om de termijn te betalen die op <b>{date}</b> verschuldigd is, d.w.z. <b>{days} dagen</b> voor de oorspronkelijk overeengekomen datum",
  i_change_my_payment_method: "Update mijn betaalmethode",
  payment_method_fingerprint_amount:
    "Op uw rekening kan een afdruk van uw bankkaart voor een bedrag van {amount} verschijnen. Dit is tijdelijk en er wordt geen bedrag van u afgetrokken.",
  card_number: "Kaartnummer",
  expiration_date: "Vervaldatum",
  security_code: "Beveiligingscode",
  card_network: "Kaart netwerk",
  notice_payment: "instructies voor het gebruik van de betalingsdienst",
  accept_cta_installment:
    "Door op Valideren te klikken, accepteer ik {0}. Tot slot verklaar ik dat de kaart die gebruikt wordt om mijn aankoop te betalen, gekoppeld is aan een rekening op mijn naam.",
  split_default_message:
    "Ik ontvang een autorisatieverzoek voor het totale bedrag. Er wordt geen bedrag afgeschreven. Mijn vrienden hebben 48 uur om te betalen. Als een van hen na deze tijd niet betaalt, wordt ik van hem gedebiteerd.",
  secured_payment: "veilige betaling",
  deadline_on: "verschuldigd op",
  with_fees_amount: "(inclusief {amount} vergoedingen)",
  basket_amount: "Mandbedrag:",
  ill_pay_later: "Ik betaal op {date}",
  ill_pay_later_on_delivery: "Ik betaal bij aflevering (en uiterlijk op {date})",
  penalties_detail: "inclusief {penalties_percentage}% late boetes: {amount}",
  not_supported_cards:
    "Prepaid-kaarten, met systematische autorisatie zoals Electron, Maestro, Nickel Account, Revolut, en virtuele kaarten of e-cards worden niet geaccepteerd.",
  payment_method_issue:
    "Er is een fout opgetreden bij het gebruik van je betaalmethode. Neem contact op met het ondersteuningsteam van de verkoper.",
  order_info_multiple_shipment:
    "De bestelling kan uit meerdere zendingen bestaan. Elke zending heeft een eigen tijdschema dat ingaat op het moment van verzending.",
  deposit_deadline_on: "Aanbetaling",
  balance_deadline_on: "Balans aan",
  balance_to_pay_later: "Ik betaal het saldo op {date}",
  test_another_card: "Probeer een andere kaart.",
  entry_error: "Invoerfout.",
  card_declined: "Je kaart is geweigerd.",
  invalid_iban: "Uw iban is onjuist",
  stripe_library_not_loaded: "Stripe V3-bibliotheek niet geladen",
  taeg: "U neemt een betalingsfaciliteit van {num_of_days} dagen, waarvoor u {amount} kosten moet betalen, d.w.z. een JKP van {taeg}%.",
  amount_paid_in_ancv: "Bedrag betaald in Chèque-Vacances",
  advertisement_optin:
    "Ik wens commerciële communicatie te ontvangen van {optin_advertiser}",
  electronic_optin:
    "Ik wil elektronische commerciële communicatie van CA Consumer Finance ontvangen",
  phone_mail_optout:
    "Ik wil geen commerciële communicatie per telefoon of post ontvangen van CA Consumer Finance",
  contract_message_start: "Ik aanvaard ",
  contract_message_link_installment:
    "de precontractuele en contractuele informatie over betaling in termijnen ",
  contract_message_link_deferred:
    "de precontractuele en contractuele informatie over uitgestelde betaling ",
  contract_message_end:
    "en verklaar dat de kaart die wordt gebruikt om mijn aankoop te betalen, gekoppeld is aan een rekening op mijn naam.",
  /** ----------------------------------------------------
   *                        COMMONS
   *  ---------------------------------------------------- */
  add: "Toevoegen",
  me: "Me",
  friend: "Vriend",
  submit: "Verzenden",
  yes: "Ja",
  no: "Nee",
  update: "Bijwerken",
  apply: "Van toepassing zijn",
  see_faq: "Zie onze FAQ voor meer informatie",
  language: "Taal:",
  back_to_website: "Terug naar {website}",
  unknown: "Onbekend",
  on_date: "Op {date}",
  footer_question_to_merchant:
    "Neem voor vragen over uw aankoop rechtstreeks contact op met {merchantName}.",
  footer_question_to_pledg:
    'Raadpleeg voor alle informatie met betrekking tot uw financiering onze <a href="{faqUrl}" target="_blank">FAQ</a> of schrijf ons op <a href="mailto:{contactEmail}"> {contactEmail} </a>.',
  notice_access_link: "Kennisgeving van het gebruik van de betaaldienst",
  data_policy_link:
    "<a href='{dataPolicyUrl}' target='_blank'>Beleid inzake gegevensprivacy</a>",
  data_policy:
    "Mijn gegevens worden gebruikt in een volledig geautomatiseerd proces om een betalingsfaciliteit toe te kennen: <a href='{dataPolicyUrl}' target='_blank'>beleid inzake gegevensprivacy</a>.",
  contact_access_link:
    "<a href='{contactUrl}' target='_blank'>Neem contact op met betalingsdienst</a>",
  no_information: "Geen informatie verstrekt",
  no_psp_source: "Geen betaalmethode beschikbaar",
  redirected_from_message:
    "{merchantName} heeft je doorgestuurd naar {operatorTeamName} om je betaling af te ronden.",
  your_purchase: "Jouw aankoop",
  your_financing: "Uw financiering",
  your_payment: "Uw betaling",
  purchase_amount: "Aankoopbedrag",
  monthly_installments: "{installments_nb} maandelijkse termijnen van",
  fixed_borrowing_rate: "Vaste rentevoet",
  nationality_short: "Nationaliteit",
  birth_last_name_short: "Geboortenaam",
  email_address: "E-mailadres",
  full_address: "Mailadres",
  birthdate: "Geboortedatum",
  birthplace: "Geboorteplaats",
  firstname: "Voornaam",
  lastname: "Naam",
  phone_number_short: "Telefoonnummer",
  read_more: "Lees meer",
  continue: "Ga verder",
  /** ----------------------------------------------------
   *                    DASHBOARD BUYER
   *  ---------------------------------------------------- */
  notice_infos: "Raadpleeg de mededeling over de betalingsdienst.",
  error_invalid_contact: "Contactpersoon is ongeldig",
  fees_amount: "(Kosten: {amount} {currency})",
  here: "hier",
  deferred_intro:
    "Status van uw uitgestelde aankoop van {date} tot {hour} ({timezone_city} time) op {merchantName}.",
  installment_intro:
    "Status van uw gesplitste aankoop van {date} tot {hour} ({timezone_city} time) op {merchantName}.",
  split_intro:
    "Status van uw gedeelde aankoop van {date} tot {hour} ({timezone_city} tijd) op {merchantName}.",
  penalties_amount: "(Sancties: {amount} {currency})",
  proceed_payment_late: "Mijn situatie regulariseren: {amount} {currency}",
  proceed_payment_in_advance: "Betaal mijn volgende termijn: {amount} {currency}",
  dashboard_buyer_your_basket: "Je mandje",
  dashboard_buyer_your_fees: "Financieringsvergoeding ({feesPercentage}%)",
  dashboard_buyer_payment_schedule: "Uw vervaldata",
  dashboard_buyer_products: "Uw producten",
  dashboard_buyer_see_schedule: "Zie de dienstregeling",
  dashboard_buyer_overdue: "Wanbetaling",
  dashboard_buyer_overdue_payment: "Regularisatie van uw situatie",
  dashboard_buyer_overdue_payment_text:
    "De betaling voor de vervaldatum van {date} is mislukt. Ga alstublieft verder met het regulariseren van uw situatie.",
  dashboard_buyer_your_payment_method: "Uw betaalmethode",
  dashboard_buyer_your_payment_method_expired_soon:
    "Uw betaalmethode staat op het punt te verlopen. Update het om betalingsincidenten te voorkomen.",
  dashboard_buyer_your_payment_method_text_installment:
    "Het afschrijven van uw maandtermijnen gebeurt op de afgesproken data via de volgende betaalmethode:",
  dashboard_buyer_your_payment_method_text_deferred:
    "Het verschuldigde bedrag wordt op de afgesproken datum geïncasseerd via de volgende betaalmethode:",
  dashboard_buyer_your_payment_method_button: "Wijzig mijn betaalmethode",
  dashboard_buyer_share_captured_amount: "Betaald:",
  dashboard_buyer_share_not_charged_amount: "Geannuleerd:",
  dashboard_buyer_share_refunded_amount: "Terugbetaald:",
  dashboard_buyer_share_outstanding_amount: "Uitstaand bedrag:",
  dashboard_buyer_title_installment:
    'Uw <span class="text-secondary">betaling in {installmentsNb} keer</span> bij {merchantName}',
  dashboard_buyer_title_deferred:
    'Uw <span class="text-secondary">uitgestelde betaling</span> bij {merchantName}',
  dashboard_buyer_title_down_payment:
    'Uw <span class="text-secondary">betaling met aanbetaling</span> bij {merchantName}',
  dashboard_buyer_title_split:
    'Uw <span class="text-secondary">gedeelde betaling</span> bij {merchantName}',
  dashboard_buyer_status_total_refund:
    "Uw aankoop is terugbetaald in overeenstemming met de algemene voorwaarden van {merchantName} en de betalingsservice.",
  dashboard_buyer_status_split_waiting_payment:
    "Niet alle deelnemers hebben hun deel betaald.",
  dashboard_buyer_status_fully_paid:
    "Uw aankoop is volledig betaald. Tot ziens bij {merchantName}.",
  dashboard_buyer_status_overdue:
    "Betaling van een termijn is mislukt. Regulariseer uw situatie of neem zo snel mogelijk contact op met de betalingsdienst.",
  dashboard_buyer_status_ongoing:
    "De betaling van uw aankoop vindt normaal plaats, u hoeft niets te doen.",
  dashboard_buyer_status_psp_source_expired:
    "Uw betaalmethode verloopt. Update het om uw termijnen te blijven betalen.",
  resend_message: "Bericht opnieuw verzenden",
  split_leader_label: "Jij",
  state_leader_not_paid: "In behandeling",
  state_expired_not_paid: "Betaling mislukt, te regulariseren",
  state_future_not_paid: "Automatische afschrijving op vervaldatum",
  state_preauthorization_ok: "Bankafdruk",
  state_sepa_debit_pending: "SEPA debet in afwachting van validatie",
  state_sofort_pending: "Sofort-betaling in behandeling",
  state_ancv_pending_transfer: "In afwachting van validatie",
  state_ancv_processing: "In afwachting van betaling door Chèques-Vacances",
  state_ancv_preauthorization_ok: "Betaald met Chèques-Vacances",
  state_payment_ok: "Betaald",
  state_paid_by_leader: "Door jou betaald",
  state_unknown: "In afwachting van betaling",
  state_fully_refunded: "Terugbetaald",
  state_fully_not_charged: "Geannuleerd",
  split_explanation_1:
    "Elke deelnemer heeft tot {date} om {hour} ({timezone_city} time) de tijd om zijn split te betalen. Na deze tijd wordt het bedrag van uw split ({amount} {currency}) in rekening gebracht als evenals onbetaalde aandelen.",
  dashboard_order_link: "Terug naar het bestel dashboard",
  header_amount: "Bedrag",
  header_participants: "Deelnemers",
  header_status: "Status",
  error_dashboard_title: "Dashboard niet beschikbaar",
  error_dashboard_subtitle: "Uw aankoopoverzicht kon niet worden weergegeven.",
  error_dashboard_reason_title: "Om welke reden?",
  error_dashboard_reason_1: "De betreffende aankoop is mogelijk niet beschikbaar",
  error_dashboard_reason_1_details:
    "Dit dashboard is niet beschikbaar voor aankopen die worden gemaakt of geannuleerd.",
  error_dashboard_reason_2: "Deze pagina is mogelijk verlopen",
  error_dashboard_reason_2_details:
    "Om veiligheidsredenen is deze pagina onderhevig aan een vervaldatum. Sluit de pagina en klik nogmaals op de originele link.",
  /** ----------------------------------------------------
   *                    STEPS TITLE
   *  ---------------------------------------------------- */
  title_payment_missing_info: "Ik voltooi mijn <span>factuurinformatie</span>",
  title_payment_solutions: "Ik kies mijn <span>financieringsplan</span>",
  title_missing_info_installment:
    "Ik betaal mijn aankoop <span>in {installmentsNb} termijnen</span>",
  title_missing_info_deferred: "Ik betaal mijn aankoop <span>uitgesteld</span>",
  title_missing_info_down_payment:
    "Ik betaal mijn aankoop <span>met aanbetaling</span>",
  title_missing_info_split: "Ik betaal mijn aankoop <span>gesplitst</span>",
  title_otp: "Ik bevestig mijn <span>telefoon</span>",
  title_revenue_collection: "Ik geef mijn inkomsten en <span>uitgaven</span> op",
  title_confirm_info: "Ik bevestig mijn <span>informatie</span>",
  title_payment: "Ik voer mijn <span>betaalmethode</span> in",
  title_processing:
    "Ik laat {operatorTeamName} mijn <span>kredietwaardigheid</span> verifiëren",
  /** ----------------------------------------------------
   *                        ERRORS
   *  ---------------------------------------------------- */
  purchaseNotFound: "Geen aankopen geassocieerd met deze URL.",
  genericError:
    "Er is een fout opgetreden. Als het opnieuw gebeurt, neem dan contact met ons op via contact{'@'}pledg.co.",
  error_infra_unavailable:
    "De service is momenteel niet beschikbaar. Laad de pagina opnieuw of probeer het later nog eens.",
  notAddedValidDestinationWarning:
    "Klik op de knop '+ Toevoegen' om uw vriend toe te voegen.",
  invalidDestination: "Dit e-mailadres / mobiele nummer is ongeldig!",
  alreadyUsedDestination: "Dit e-mailadres / mobiele nummer is al in gebruik!",
  invalidAmount: "Dit bedrag is niet geldig!",
  closingWarning: "Wil je dat venster echt sluiten?",
  singleShareWarning: "Wil je echt alleen betalen?",
  Confirmation3DSTimeoutError: "Verificatie mislukt. Probeer het opnieuw.",
  ancvConfirmationTimeoutError:
    "Fout bij het betalen met Chèques-Vacances. Probeer het nog eens.",
  requiredPropertyMissingOrInvalid:
    "De vereiste eigenschap {property} ontbreekt of is ongeldig",
  requiredPropertyMissing: "De vereiste eigenschap {property} ontbreekt",
  propertyInvalid: "De opgegeven eigenschap {property} is ongeldig",
  paymentRefused: "Betaling is geweigerd",
  errorHappened:
    "<strong>Verzoek om betalingsfaciliteit geweigerd, gelieve een andere betalingsmethode te gebruiken.</strong>",
  errorAmountHold:
    "Afhankelijk van je bank kan je rekening worden gedebiteerd. Dit is tijdelijk en er wordt geen bedrag van je rekening afgeschreven.",
  retry_error: "Verzoek om betalingsfaciliteit geweigerd.",
  ineligibilityPaymentMethod: "Gebruik een andere betaalmethode",
  ineligibilityPaymentMethodRedirect:
    "U wordt doorgestuurd naar een andere betaalmethode",
  ineligibilityPaymentMethodRedirectWithMerchant:
    "U wordt doorgestuurd naar de website van {merchantName} om een andere betaalmethode te kiezen.",
  ineligibilityErrorContentWithMerchant:
    "Basándonos en la información facilitada por {merchantName} o recogida directamente por el proveedor de pagos y tras un proceso de decisión totalmente automatizado, su solicitud no cumple los criterios de elegibilidad para este mecanismo de pago. Esta decisión no prejuzga el resultado de futuras solicitudes de financiación y puede ponerse en contacto con nosotros escribiendo a {infoEmail} para obtener más información.",
  ineligibilityErrorContent:
    "Op basis van rechtstreeks door de betalingsprovider verzamelde informatie en na een volledig geautomatiseerd besluitvormingsproces voldoet uw aanvraag niet aan de criteria om in aanmerking te komen voor deze betalingsfaciliteit. Deze beslissing loopt niet vooruit op de uitkomst van toekomstige financieringsaanvragen en u kunt contact met ons opnemen door te schrijven naar {infoEmail} voor meer informatie.",
  provideMerchantUid: "Geef een verkoper-uid op",
  invalidPhoneNumber: "Dette telefonnummer er ikke gyldigt.",
  IdentificationConfirmationTimeoutError:
    "Er is een fout opgetreden tijdens het verificatieproces. Als het opnieuw gebeurt, neem dan contact met ons op via {contact_email}.",
  splitIncorrectAmounts: "De ingevoerde bedragen blijken onjuist te zijn",
  fipenDownloadError:
    "Er is een fout opgetreden bij het ophalen van uw precontractuele informatie. Probeer het later nog eens.",
  contractDownloadError:
    "Er is een fout opgetreden bij het ophalen van uw contractuele informatie. Probeer het later nog eens.",
  contractSignError:
    "Er is een fout opgetreden tijdens het ondertekenen van uw contract. Probeer het later nog eens.",
  /** ----------------------------------------------------
   *                        IDENTIFICATION
   *  ---------------------------------------------------- */
  identity_check: "Identiteitscontrole",
  identification_success: "De verificatie is gelukt",
  identification_failure: "De verificatie is mislukt",
  identification_processing: "Verificatie is bezig",
  identity_check_warning_message:
    "U staat op het punt om te worden doorgestuurd om uw identiteit te verifiëren.",
  /** ----------------------------------------------------
   *                    INTRODUCTION
   *  ---------------------------------------------------- */
  intro_request_financing: "Ik vraag een <span>financiering</span> aan",
  intro_title: "{installments_nb}-termijnen betalingsplan",
  intro_subtitle_1: "Krediet is een verplichting en moet worden terugbetaald",
  intro_subtitle_2:
    "Controleer je terugbetalingscapaciteit voordat je een toezegging doet",
  intro_deposit_amount: "Aanbetaling (exclusief financiering)",
  intro_credit_amount: "Kredietbedrag",
  intro_financing_period: "Financieringsperiode",
  intro_financing_period_value: "{installments_nb} maanden",
  intro_taeg: '<span class="text-2xl">Vast JKP</span>',
  intro_credit_cost: "Kosten van krediet",
  intro_total_amount_due:
    'Dat is een <span class="text-2xl">totaal verschuldigd bedrag van</span>',
  intro_total_payment_cost: "Totaal te betalen (aanbetaling + kredietfinanciering)",
  intro_financing_modal_text:
    "<p class='text-xl font-bold mb-4'>Krediet is een verplichting en moet worden terugbetaald. Controleer of je kunt terugbetalen voordat je een verplichting aangaat.</p>" +
    "<p class='text-base mb-4'>Voor een lening van {financed_amount} bij een verkoop, na een aanbetaling van {deposit_amount}, betaal je terug in {financing_month_count} <span class='text-xl font-bold'>maandelijkse termijnen van {derived_share_amount}. Totaal verschuldigd bedrag: {financed_total_outstanding_amount}</span>. Totale kosten van de aankoop: {total_amount}. <span class='text-xl font-bold'>Vast jaarlijks kostenpercentage (JKP) van {taeg}</span>. Vaste leenrente van {buyer_fees_percentage}.</p>" +
    "<p class='text-base'>Bijkomend kredietaanbod voorbehouden aan particulieren onder voorbehoud van definitieve aanvaarding door CA Consumer Finance, de kredietverstrekker, waarvan Sofinco een merk is. SA au capital de 596 485 149 € - 1 rue Victor Basch - CS 70001, 91068 Massy cedex - RCS Evry 542 097 5222. Verzekeringstussenpersoon ingeschreven bij ORIAS nr. 07 008 079 (www.orias.fr). Dit aanbod omvat geen verzekering. Je hebt een wettelijk herroepingsrecht. De kosten van het krediet zijn voor rekening van de verkoper.</p>",
  /** ----------------------------------------------------
   *                    INFORMATION MISSING
   *  ---------------------------------------------------- */
  address_street: "Adres (nummer, straat enz ...)",
  address_zipcode: "Postcode (bijv. 1011 AB)",
  birth_address_zipcode: "Postcode van geboorte (bijv. 1011 AB)",
  address_city: "Stad (bijv. Amsterdam)",
  civility: "Beleefdheid (zoals vermeld op uw identiteitsbewijs)",
  birth_last_name: "Geboortenaam (bijv. De Vries)",
  madam: "Mevrouw",
  mister: "Meneer",
  address_province: "Regio",
  address_country: "Land (bijv. Nederland)",
  birth_date_placeholder: "Geboortedatum (dd/mm/jjjj)",
  birth_city: "Geboortestad",
  birth_country: "Geboorteland",
  search_birthplace: "Geboorteplaats (bijv. 1011 AB Amsterdam, Nederland)",
  birth_place_autocomplete_error: "Selecteer een geboorteplaats",
  nationality: "Nationaliteit",
  phone_number: "Telefonnummer (bijv. +31612345678)",
  missing_information_title: "We hebben wat extra informatie nodig",
  missing_information_subtitle:
    "Om verder te kunnen gaan met je financieringsaanvraag, hebben we meer informatie nodig. ",
  missing_information_subtitle_payer: "Vul uw factuurgegevens in.",
  email: "E-mail",
  day: "Dag",
  month: "Maand",
  year: "Jaar",
  fipen_text: "U kunt nu ",
  fipen_link: "uw precontractuele informatie.",
  /** ----------------------------------------------------
   *                    REVENUE
   *  ---------------------------------------------------- */
  title_revenue: "We hebben wat meer informatie nodig",
  revenue_explanation:
    "Om zeker te zijn van je leencapaciteit, hebben we informatie nodig over je inkomen en je maandelijkse uitgaven.",
  declared_revenue: "Inkomsten na belasting.",
  declared_expenditure: "Totale uitgaven",
  declared_csp: "Professionele categorie",
  tooltip_revenue: "Netto salaris, financiële steun, inkomsten uit onroerend goed...",
  tooltip_expenditure: "Lopende kredieten, huur, alimentatie",
  back_to_merchant: "Ik begrijp het, breng me terug naar de handelaar",
  professional_categories: [
    "Leidinggevenden managers",
    "Intellectuele en wetenschappelijke beroepen",
    "Middelbare beroepen in loondienst",
    "Kleine ondernemers (geen werknemers)",
    "Geschoolde werknemers",
    "Geschoolde arbeiders in loondienst",
    "Laaggeschoolde beroepen in loondienst",
    "Gepensioneerden",
    "Andere werklozen",
  ],
  /** ----------------------------------------------------
   *                    SUMMARY
   *  ---------------------------------------------------- */
  title_summary: "Samenvatting van uw bestand",
  summary_explanation: "Bevestig je gegevens voordat je je contract ondertekent.",
  summary_unchangeable: "Niet aanpasbaar",
  summary_your_identity: "Je identiteit",
  summary_your_revenue: "Uw financiën",
  summary_your_contact_details: "Uw contactgegevens",
  summary_deposit_amount: "Bedrag van storting",
  summary_financed_amount: "Bedrag gefinancierd",
  summary_total_amount_due: "Totaal verschuldigd bedrag",
  summary_net_monthly_income: "Netto maandelijks inkomen",
  summary_recurring_expenses: "Terugkerende kosten",
  summary_csp: "Professionele categorie",
  summary_acknowledgement_statement:
    "Ik bevestig dat ik het gestandaardiseerde Europese precontractuele informatieblad heb gelezen. Ik verklaar op erewoord dat de bovenstaande informatie correct is, verklaar dat ik geen lopend plan voor overmatige schuldenlast heb en verklaar dat de aangevraagde lening niet bedoeld is voor schuldconsolidatie",
  summary_terms_and_conditions:
    "Ik bevestig dat ik de algemene voorwaarden van digitale diensten heb gelezen en geaccepteerd",
  /** ----------------------------------------------------
   *                    INFORMATION SPLIT
   *  ---------------------------------------------------- */
  title_whodoisharewith: "Met welke vrienden deel ik mijn bestelling?",
  subtitle_addfriends:
    "Ik voeg de e-mailadressen of de mobiele nummers van mijn vrienden toe",
  placeholder_friend_email_phone_number:
    "Het e-mailadres of mobiele nummer van mijn vriend",
  attendees: "Geen medekoper | 1 mede-koper | {count} co-kopers",
  pay_alone: "Ik wil alleen betalen",
  /** ----------------------------------------------------
   *                        PAYMENT SOLUTIONS
   *  ---------------------------------------------------- */
  payment_solutions_title: "Mijn betalingsmogelijkheden",
  payment_solutions_subtitle: "bij {company}",
  payment_solutions_choice_infos: "Selecteer de betalingsfaciliteit die bij u past",
  payment_schedule_installment_homogeneous: "{installments_nb}x {amount}",
  payment_schedule_installment_disparate:
    "{amount} vervolgens {installments_nb}x {second_amount}",
  payment_schedule_deferred_delay_in_days: "{amount} binnen {delay_in_days} dagen",
  payment_schedule_deferred_day_of_next_month: "{amount} de {payment_date}",
  payment_solutions_no_merchants_found:
    "Geen enkele betaaloplossing voldoet aan de toelatingscriteria op basis van de verstrekte informatie.",
  /** ----------------------------------------------------
   *                        PROCESSING
   *  ---------------------------------------------------- */
  payment_pending: "Betaling in behandeling",
  payment_pending_information_1:
    "Vernieuw de pagina niet tijdens het verwerken van de informatie.",
  payment_pending_information_2: "Deze stap kan een paar minuten duren.",
  payment_cancelled: "Betaling geannuleerd",
  payment_analysis_needed: "Aanvullende informatie vereist",
  payment_analysis_information_1:
    "Als onderdeel van uw financieringsaanvraag willen we graag een 90-daagse geschiedenis van uw transacties verzamelen bij uw bank.",
  payment_analysis_information_2:
    "Deze service wordt beheerd door onze partner {providerLabel}, een leider in het veilig delen van bankgegevens, goedgekeurd door de ACPR.",
  payment_analysis_information_3:
    "Door op Valideren te klikken, ga ik akkoord met het doorsturen naar {operatorTeamName} van de informatie uit mijn bankafschriften.",
  payment_analysis_information_4:
    "Als u dit niet wenst te doen, kunt u terugkeren naar de selectie van betaalmethoden voor {merchantName} door op het kruisje te klikken.",
  payment_analysis_in_progress: "Analyse bezig",
  payment_analysis_progress_information_1:
    "Uw aanvraag voor een betalingsfaciliteit wordt geanalyseerd.",
  payment_analysis_progress_information_2:
    "Ververs de pagina niet terwijl informatie wordt verwerkt. Deze stap kan enkele minuten duren.",
  /** ----------------------------------------------------
   *                        SUCCESS
   *  ---------------------------------------------------- */
  congratulations: "Gefeliciteerd!",
  payment_registered: "Uw betaling is geregistreerd.",
  payment_method_registered: "Er is rekening gehouden met uw nieuwe betaalmethode.",
  /** ----------------------------------------------------
   *                        TWO-FA
   *  ---------------------------------------------------- */
  phone_number_check: "Het controleren van uw telefoonnummer",
  phone_number_tooltip:
    "Dit is het telefoonnummer dat u aan de handelaar heeft gegeven.",
  wrong_code: "Verkeerde code, probeer het opnieuw",
  unknown_error: "Er is een onbekende fout opgetreden",
  code_valid: "Code is geldig",
  please_type_code: "Typ deze code in het onderstaande veld",
  you_ll_receive_text: "We hebben u zojuist een code gestuurd naar ",
  did_not_receive: "Ik heb niet ontvangen",
  send_again: "verzenden egain",
  sending_sms: "Codeverzending wordt uitgevoerd",
  verify: "Verifieer",
  code_transmission_error:
    "Er is een fout opgetreden tijdens het verzenden van de code. Probeer het opnieuw",
  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  validations: {
    required: "Het veld is verplicht",
    phone_number: "Het telefoonnummer is ongeldig",
    date_format: "Het datumformaat is niet geldig ({_value_})",
    invalid_birthdate: "Voer een geldige geboortedatum in",
    date_between: "De datum is niet ongeldig",
    min: "Het veld moet minimaal {length} tekens bevatten.",
    positive: "De waarde moet een positief getal zijn.",
    email: "Het e-mail formaat is ongeldig ({_value_})",
  },
  /** ----------------------------------------------------
   *                        CUSTOM SCREEN
   *  ---------------------------------------------------- */
  custom_success_screen: {
    title: `Uw boeking is niet voltooid totdat u op "Nu kopen" klikt"`,
    subtitle:
      "Zodra uw boeking compleet is, wordt uw reis volledig betaald en bent u klaar om te reizen.",
    text: "Mogelijk verschijnt er een pre-autorisatie op uw bankrekening. We zullen u op de hoogte stellen voordat elke betaling verschuldigd is. Gemiste betalingen kunnen leiden tot annulering van de reis.",
  },
  /** ----------------------------------------------------
   *                        ERROR SCREEN
   *  ---------------------------------------------------- */
  error_screen: {
    error_410: "De opgevraagde bron is niet langer beschikbaar",
    error_404: "Fout 404",
    expired: "De opgevraagde URL is verlopen",
    not_found: "De pagina die u zoekt is niet gevonden",
  },
};
