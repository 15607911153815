import { normalize } from "normalizr";
import _merge from "lodash.merge";
import { merchantSchema } from "@/store/schemas.js";
import * as types from "@/store/mutation-types";
import MerchantService from "../../api/merchantService";
import {
  PaymentTypes,
  DEFAULT_CONTACT_EMAIL,
  DEFAULT_INFO_EMAIL,
} from "../../constants";

// Initial state
const state = {
  all: {},
};

// Getters
const getters = {
  allMerchants: (merchantState) => merchantState.all,
  merchant: (merchantState, merchantGetters, rootState, rootGetters) => {
    return merchantGetters.allMerchants[rootGetters.rawMerchantUid];
  },
  dataPolicyUrl: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.data_policy_url,
  merchantName: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.name || "",
  merchantOperator: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.branding?.toUpperCase(),
  merchantFolderName: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.folder_name,
  merchantCSSUrl: (merchantState, merchantGetters) => merchantGetters.merchant?.css_url,
  merchantPictureUrl: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.picture_url,
  merchantLanguage: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.language,
  isInstallmentPayment: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.payment_type === PaymentTypes.INSTALLMENT,
  isDeferredPayment: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.payment_type === PaymentTypes.DEFERRED,
  isDownPayment: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.payment_type === PaymentTypes.DOWN_PAYMENT,
  isSplitPayment: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.payment_type === PaymentTypes.SPLIT,
  isAncvPayment: (merchantState, merchantGetters) =>
    !!merchantGetters.merchant?.ancv_params?.shop_id,
  isPledgLongCb: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.is_pledg_long_cb,
  faqUrl: (merchantState, merchantGetters) => merchantGetters.merchant?.faq_url,
  webSessionId: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.web_session_id,
  merchantDisabled: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.disabled || false,
  enableAdvertisementOptin: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.enable_advertisement_optin,
  merchantOptinAdvertiser: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.optin_advertiser,
  enableCustomSuccessScreen: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.enable_front_custom_success_screen || false,
  paymentTypeLower: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.payment_type?.toLowerCase(),
  installmentsNb: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.installments_nb,
  contactEmail: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.contact_email || DEFAULT_CONTACT_EMAIL,
  infoEmail: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.info_email || DEFAULT_INFO_EMAIL,
  contactUrl: (merchantState, merchantGetters) => merchantGetters.merchant?.contact_url,
  displayCGULink: (merchantState, merchantGetters) => {
    const { merchant } = merchantGetters;
    if (!merchant) return false;
    return !!merchant.cgu_pdf_file && !merchant.is_buyer_contract_eligible;
  },
  cssVariables: (merchantState, merchantGetters) => {
    const { merchant } = merchantGetters;
    return merchant?.css_settings?.variables;
  },
  isBuyerContractEligible: (merchantState, merchantGetters) =>
    merchantGetters.merchant?.is_buyer_contract_eligible,
};

// Actions
const actions = {
  async fetchMerchantAction({ commit, rootGetters }, { merchantUid, pledgEnv }) {
    // If iframe_id is undefined, it means that the front is not called from the plugin
    // and pledgEnv is therefore not set.
    const response = await MerchantService.fetchMerchant(merchantUid, {
      iframe_id: rootGetters.rawIframeId,
      pledg_env: pledgEnv,
    });
    if (response?.data) {
      const { entities, result } = normalize(response.data, merchantSchema);
      commit(types.MERCHANT_GET_SUCCESS, { entities, result });
      commit(types.RAW_SET_MERCHANT_UID, { merchantUid }, { root: true });
    }
  },
};

// Mutations
const mutations = {
  [types.MERCHANT_GET_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.merchants);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
